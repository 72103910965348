import {
  ApolloClient,
  DefaultContext,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client/core";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "apollo-utilities";
import {
  ApolloLink,
  from,
  split,
  Operation,
  NextLink,
} from "@apollo/client/link/core";
// import { setContext } from "@apollo/client/link/context";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { HttpLink } from "@apollo/client/link/http";
import { FragmentDefinitionNode, OperationDefinitionNode } from "graphql";
import { useAuth } from "@clerk/clerk-react";
import { PropsWithChildren, useMemo, useState } from "react";
import { ApolloProvider } from "@apollo/client";

// export the client as a window object if it does not exist
export interface customWindow extends Window {
  "apollo-client": ApolloClient<NormalizedCacheObject>;
}
declare const window: customWindow;

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { getToken, userId, sessionId } = useAuth();

  const [token, setToken] = useState<string | null>(null);

  const client = useMemo(() => {
    // obtain the host name;
    const hostName = window.location.hostname;
    let routeUrl = `http://${hostName}:4000/graphql`;
    let wsUrl = `ws://${hostName}:4000/subscriptions`;
    if (process.env.REACT_APP_GQL_URL) {
      routeUrl = process.env.REACT_APP_GQL_URL;
    }
    if (process.env.REACT_APP_GQL_WS_URL) {
      wsUrl = process.env.REACT_APP_GQL_WS_URL;
    }

    console.log("[ApolloProviderWrapper] routeUrl", routeUrl);
    console.log("[ApolloProviderWrapper] wsUrl", wsUrl);

    console.log(
      "[ApolloProviderWrapper] userId",
      userId ? userId.substring(0, 10) + "..." : "no user id "
    );
    console.log(
      "[ApolloProviderWrapper] sessionId",
      sessionId ? sessionId.substring(0, 10) + "..." : "no session id "
    );

    /**
     * WebSocket link for GraphQL communication.
     */
    const wsLink = new GraphQLWsLink(
      createClient({
        url: wsUrl,
        connectionParams: {
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          sessionId: sessionId,
          userId: userId,
          authorization: `Bearer ${token}`,
        },
        on: {
          connected: () => console.log("GraphQLWsLink connected"),
          closed: () => console.log("GraphQLWsLink closed"),
        },
      })
    );
    // create a httpLink
    const httpLink: HttpLink = new HttpLink({
      uri: routeUrl,
    });

    const authLink = new ApolloLink(
      (operation: Operation, forward: NextLink) => {
        // add the authorization to the headers
        const headers: any = {
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          sessionid: sessionId,
          userid: userId,
          authorization: "empty",
        };
        const contextOptions: DefaultContext = {
          headers,
        };
        if (token) headers.authorization = `Bearer ${token}`;
        else {
          getToken().then((userToken: any) => {
            setToken(userToken);
            headers.authorization = `Bearer ${userToken}`;
          });
        }
        operation.setContext(contextOptions);

        return forward(operation);
      }
    );

    // create error link
    const errorLink: ApolloLink = onError(
      ({ graphQLErrors, networkError }: ErrorResponse) => {
        if (graphQLErrors) {
          graphQLErrors.forEach((error: any) => {
            console.error(
              `[apollo.errorLink] GraphQL error: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
            );
          });
        }
        if (networkError) {
          console.error(`[apollo.errorLink] Network error: ${networkError}`);
        }
      }
    );

    // Prevent ApolloClient from retrying infinitely on failures
    // Doc: https://www.apollographql.com/docs/link/links/retry/
    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: 20000,
        jitter: true,
      },
      attempts: {
        max: 3,
        retryIf: (error) => !!error,
      },
    });

    const opsLink: ApolloLink = from([
      errorLink,
      authLink,
      retryLink,
      httpLink,
    ]);

    const terminatingLink: ApolloLink = split(
      ({ query }: Operation) => {
        const mainDefinition: OperationDefinitionNode | FragmentDefinitionNode =
          getMainDefinition(query);
        return (
          mainDefinition.kind === "OperationDefinition" &&
          mainDefinition.operation === "subscription"
        );
      },
      wsLink,
      opsLink
    );

    let clientInstance: ApolloClient<NormalizedCacheObject>;
    if (!window["apollo-client"]) {
      const newClient = new ApolloClient({
        name: "elementary-ai-client",
        link: terminatingLink,
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "cache-first",
            nextFetchPolicy: "cache-and-network",
          },
          query: {
            fetchPolicy: "cache-first",
            errorPolicy: "all",
          },
        },
      });
      window["apollo-client"] = newClient;
      clientInstance = newClient;
    } else clientInstance = window["apollo-client"];

    return clientInstance;
  }, [userId, sessionId, token, getToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
