import { Card } from "@mui/material";
import { User } from "../../../graphql/types";
import { UserCardContent } from "./UserCardContent";

export interface UserCardProps {
  user: User;
  loading?: boolean;
}

export function UserCard({ user, loading }: UserCardProps) {
  return (
    <Card variant="outlined" sx={{ marginTop: 2 }}>
      <UserCardContent user={user} loading={loading} />
    </Card>
  );
}
