import { useMutation } from "@apollo/client";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Stack } from "@mui/system";
import { useState } from "react";
import { Rating } from "../../../graphql/types";
import { RATE_QUESION, RATING_LIST } from "../../../graphql/ratings";
import { errorNofification } from "../../notification/notification";
import { LoadingButton } from "@mui/lab";

export function RateQuestion(props: { askId: string; disabled?: boolean }) {
  const [rating, setRating] = useState(Rating.Unrated);
  const [rate, { loading, error }] = useMutation(RATE_QUESION);
  function onRated(rating: Rating) {
    console.log("[RateQuestion] onRated", rating);
    setRating(rating);
  }
  if (error) errorNofification({ error: error });

  return (
    <Card sx={{ margin: "10px" }}>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <RateAnswer
            rating={rating}
            onRated={onRated}
            disabled={props.disabled}
          />
          <LoadingButton
            loading={loading}
            disabled={props.disabled}
            variant="outlined"
            onClick={() => {
              console.log(rating);
              rate({
                variables: {
                  askId: props.askId,
                  rating: rating,
                },
                refetchQueries: [RATE_QUESION, RATING_LIST],
              });
            }}
          >
            Rate
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
}

export function RateAnswer(props: {
  rating: Rating;
  onRated: (rating: Rating) => void;
  disabled?: boolean;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onRated) {
      const ratingValue = (event.target as HTMLInputElement).value as Rating;
      props.onRated(ratingValue);
    }
  };
  return (
    <RadioGroup row onChange={handleChange} value={props.rating}>
      <FormControlLabel
        disabled={props.disabled}
        value={Rating.Good}
        control={<Radio />}
        label="Good"
      />
      <FormControlLabel
        disabled={props.disabled}
        value={Rating.Neutral}
        control={<Radio />}
        label="Neutral"
      />
      <FormControlLabel
        disabled={props.disabled}
        value={Rating.Bad}
        control={<Radio />}
        label="Bad"
      />
      <FormControlLabel
        disabled={props.disabled}
        value={Rating.Unrated}
        control={<Radio />}
        label="Unrated"
      />
    </RadioGroup>
  );
}
