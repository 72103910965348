import { Navigate, Route, Routes } from "react-router-dom";

import { PublicMain } from "./PublicMain";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import { TitleQuestion } from "../chat/TitleQuestion";
import { UserList } from "../admin/user/UserList";
import { RatingList } from "../admin/rating/RatingList";
import { AskChat } from "../chat/TitleChat";
import { TestSuiteList } from "../admin/rating/tests/TestSuiteList";
import SynonymList from "../admin/synonym/SynonymList";
import { ProductChooser } from "../products/ProductChooser";
import { PaymentCompletion } from "../subscriptions/PaymentCompletion";
import { PaymentCancelation } from "../subscriptions/PaymentCancelation";
import { PriceList } from "../products/PricesList";
import { PricingTable } from "../products/PricingTable";
import { NewProductCard } from "../products/NewProductCard";

export function AppRoutes() {
  return (
    <Routes>
      <Route
        path="admin/synonyms"
        element={
          <SignedIn>
            <SynonymList />
          </SignedIn>
        }
      />
      <Route
        path="admin/synonyms/:synonymId"
        element={
          <SignedIn>
            <SynonymList />
          </SignedIn>
        }
      />
      <Route
        path="admin/tests"
        element={
          <SignedIn>
            <TestSuiteList />
          </SignedIn>
        }
      />
      <Route
        path="admin/tests/:testSuiteId"
        element={
          <SignedIn>
            <TestSuiteList />
          </SignedIn>
        }
      />
      <Route
        path="admin/ratings"
        element={
          <SignedIn>
            <RatingList />
          </SignedIn>
        }
      />
      <Route
        path="admin/ratings/:askId"
        element={
          <SignedIn>
            <RatingList />
          </SignedIn>
        }
      />
      <Route
        path="admin/users"
        element={
          <SignedIn>
            <UserList />
          </SignedIn>
        }
      />
      <Route
        path="admin/users/:userId"
        element={
          <SignedIn>
            <UserList />
          </SignedIn>
        }
      />
      <Route
        path="admin/prices"
        element={
          <SignedIn>
            <PriceList />
          </SignedIn>
        }
      />
      <Route
        path="admin/prices/table"
        element={
          <SignedIn>
            <PricingTable />
          </SignedIn>
        }
      />
      <Route
        path="admin/products"
        element={
          <SignedIn>
            <ProductChooser />
          </SignedIn>
        }
      />
      <Route
        path="admin/products/new"
        element={
          <SignedIn>
            <NewProductCard />
          </SignedIn>
        }
      />
      <Route path="admin/payment/completion" element={<PaymentCompletion />} />
      <Route path="admin/payment/cancel" element={<PaymentCancelation />} />
      <Route path="admin/payment/completion" element={<PaymentCompletion />} />
      <Route
        path="admin/subscription/:subscriptionId"
        element={
          <SignedIn>
            <UserList />
          </SignedIn>
        }
      />
      <Route
        path="ai/question"
        element={
          <SignedIn>
            <TitleQuestion />
          </SignedIn>
        }
      />
      <Route
        path="ai/ask"
        element={
          <SignedIn>
            <AskChat />
          </SignedIn>
        }
      />

      <Route path="/" element={<Navigate replace to="/main" />} />
      <Route
        path="main"
        element={
          <>
            <SignedOut>
              <PublicMain />
            </SignedOut>
            <SignedIn>
              <Navigate replace to={"/ai/question"} />
            </SignedIn>
          </>
        }
      />
    </Routes>
  );
}
