import { gql } from "@apollo/client";

export const TEST_LIST = gql`
  query TestList ($rating: [Rating!]){
    testSuits (rating: $rating){
      id
      timestamp
      name
    
      
    }
  }
`;

export const TEST_DETAILS = gql`

  query TestDetails($id: ID!) {
    testSuit(testId: $id) {
      id
      timestamp
      name
      results{
        question
        synonymQuestion
        answer
        rating
        prompt
        sources{
          id
          name
          type
          source
          relevance
        }
      }
    }
  }
`;


export const RATE_TEST_RESULT = gql`
mutation RateTestResult($testSuiteId: ID!, $testIndex:Int! $rating: Rating!) {
  rateTestResult(testSuiteId: $testSuiteId, testIndex: $testIndex rating: $rating) {
    id
  }
}
`;

export const SOURCE_RELEVANCE = gql` 

mutation SourceRelevance($testSuiteId: ID!, $testIndex:Int!  $sourceId: ID!, $relevance: SourceRelevance!) {
  sourceRelevance(testSuiteId: $testSuiteId, testIndex: $testIndex, sourceId:$sourceId,relevance: $relevance) {
    id

  }
}
`;

export const RATING_LIST = gql`
  query RatingList {
    ratings {
      askId
      questionId
      question
      answer
      rating
      createdAt
    }
  }
`;

export const RATING_DETAILS = gql`
  query RatingDetails($askId: ID!) {
    rating(askId: $askId) {
      askId
      questionId
      question
      answer
      rating
      createdAt
    }
  }
`;

export const RATE_QUESION = gql`
mutation Rate($askId: ID!,  $rating: Rating!) {
  rate(askId: $askId, rating: $rating) {
    askId
    rating
  }
}
`;