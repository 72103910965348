import { Chip } from '@mui/material';
import { Customer } from '../../graphql/types';

export enum SubscriptionStatus {
  Subscribed = 'Subscribed',
  Trial = 'Trial',
  Cancelled = 'Cancelled',
  Inactive = 'Inactive'
}

export function customerSubscriptionStatus(customer: Customer | undefined | null) {
  let status: SubscriptionStatus = SubscriptionStatus.Trial;
  console.log('customer.subscriptions', customer?.subscriptions);
  if (customer && customer.subscriptions && customer.subscriptions.length > 0) {
    const subscriptions = customer.subscriptions;

    if (subscriptions.some((s: any) => s.status === 'active'))
      status = SubscriptionStatus.Subscribed;
    else if (subscriptions.some((s: any) => s.status === 'active' && s.canceledAt))
      status = SubscriptionStatus.Cancelled;



  }
  return status;
}



interface SubscriptionChipProps {
  status: SubscriptionStatus;
}
export function SubscriptionChip({ status }: SubscriptionChipProps) {
  switch (status) {
    case SubscriptionStatus.Subscribed:
      return (
        <Chip label='Subscribed' color='success' />
      );

    case SubscriptionStatus.Cancelled:
      return (
        <Chip label='Cancelled' color='warning' />
      );

    case SubscriptionStatus.Inactive:
      return (
        <Chip label='Inactive' color='error' />
      );
    default:
      return (
        <Chip label='Trial' color='secondary' />
      );
  }

}
