import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { tableCellClasses } from "@mui/material/TableCell";

import { Address } from "../../graphql/types";
import { Box } from "@mui/system";

interface AddressCardProps {
  address: Address | undefined | null;
}
export function AddressCard({ address }: AddressCardProps) {
  return (
    <TableContainer>
      <Typography variant="h6" gutterBottom>
        Address
      </Typography>
      <Table
        size="small"
        aria-label="address-table"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableBody>
          <TableRow key="line-1">
            <TableCell component="th" scope="row">
              <strong>Line 1:</strong>
            </TableCell>
            <TableCell>{address?.line1}</TableCell>
          </TableRow>

          <TableRow key="line-2">
            <TableCell>
              <strong>Line 2:</strong>
            </TableCell>
            <TableCell>{address?.line2}</TableCell>
          </TableRow>

          <TableRow key="post-code">
            <TableCell>
              <strong>Postal Code:</strong>
            </TableCell>
            <TableCell>{address?.postalCode}</TableCell>
          </TableRow>
          <TableRow key="city">
            <TableCell>
              <strong>City:</strong>
            </TableCell>
            <TableCell>{address?.city}</TableCell>
          </TableRow>

          <TableRow key="state">
            <TableCell>
              <strong>State:</strong>
            </TableCell>
            <TableCell>{address?.state}</TableCell>
          </TableRow>

          <TableRow key="country">
            <TableCell>
              <strong>Country:</strong>
            </TableCell>
            <TableCell>{address?.country}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export function AddressCardOld({ address }: AddressCardProps) {
  return (
    <Box>
      <Card variant="outlined" sx={{ marginTop: 2 }}>
        <CardHeader title="Address" />
        <CardContent>
          <Typography>
            <strong>Line 1:</strong> {address?.line1 || ""}
          </Typography>
          {address?.line2 && (
            <Typography>
              <strong>Line 2:</strong> {address?.line2 || ""}
            </Typography>
          )}
          <Typography>
            <strong>City:</strong> {address?.city || ""}
          </Typography>
          <Typography>
            <strong>Postal Code:</strong> {address?.postalCode || ""}
          </Typography>
          <Typography>
            <strong>State:</strong> {address?.state || ""}
          </Typography>

          <Typography>
            <strong>Country:</strong> {address?.country || ""}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
