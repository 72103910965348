import { LoadingButton } from "@mui/lab";
import { Box, Card, CardActions, CardContent, Stack } from "@mui/material";
import React, { useId, useState } from "react";
import LoadingTextField from "../common/LoadingTextField";
import { useMutation } from "@apollo/client";
import { NEW_PRODUCT_OVERAGE } from "../../graphql/stripe";
import { errorNofification } from "../notification/notification";
import {
  NewProductFixedFeeOverageMutation,
  NewProductFixedFeeOverageMutationVariables,
  RecuringInterval,
} from "../../graphql/types";

export function NewProductCard() {
  const id = useId();
  const [newProduct, setNewProduct] =
    useState<NewProductFixedFeeOverageMutationVariables>({
      productName: "",
      productDescription: "",
      fixedFee: 0.0,
      upTo: 0,
      overagePricePerUnit: 0.0,
      interval: RecuringInterval.Month,
    });

  function onChange(event: any) {
    setNewProduct({
      ...newProduct,
      [event.target.id]: event.target.value,
    });
  }

  function onNumberChange(event: any) {
    setNewProduct({
      ...newProduct,
      [event.target.id]: Number.parseFloat(event.target.value),
    });
  }

  const [createProduct, { data, loading, error }] = useMutation<
    NewProductFixedFeeOverageMutation,
    NewProductFixedFeeOverageMutationVariables
  >(NEW_PRODUCT_OVERAGE);

  console.log(newProduct, data);

  if (error) errorNofification({ error });

  return (
    <Box>
      <Card id={id} sx={{ minWidth: 500 }}>
        <CardActions>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              createProduct({
                variables: newProduct,
              });
            }}
          >
            New Product
          </LoadingButton>
        </CardActions>
        <CardContent>
          <Stack spacing="1em">
            <LoadingTextField
              id="productName"
              label="Name"
              placeholder="product name"
              //   disabled={props.loading || props.disabled}
              onChange={onChange}
              defaultValue={newProduct.productName}
              required
            />
            <LoadingTextField
              id="productDescription"
              label="Description"
              placeholder="product description"
              //   disabled={props.loading || props.disabled}
              onChange={onChange}
              defaultValue={newProduct.productDescription}
              required
            />
            <LoadingTextField
              id="fixedFee"
              label="Fixed fee"
              placeholder="fixed fee"
              inputProps={{ type: "number" }}
              onChange={onNumberChange}
              defaultValue={newProduct.fixedFee}
              required
            />
            <LoadingTextField
              id="upTo"
              label="Up to"
              placeholder="up to"
              inputProps={{ type: "number" }}
              onChange={onNumberChange}
              defaultValue={newProduct.upTo}
              required
            />
            <LoadingTextField
              id="overagePricePerUnit"
              label="Overage price per unit"
              placeholder="overage per unit"
              inputProps={{ type: "number" }}
              onChange={onNumberChange}
              defaultValue={newProduct.overagePricePerUnit}
              required
            />
            <LoadingTextField
              id="interval"
              label="Interval"
              placeholder="interval"
              onChange={onChange}
              defaultValue={newProduct.interval}
              required
            />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
