import {
  useUser,
  SignOutButton,
  useClerk,
  SignedOut,
  SignedIn,
} from "@clerk/clerk-react";
import { Tooltip, IconButton, MenuItem, ListItemIcon } from "@mui/material";
import { useNavigate } from "react-router";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
interface SignoutMenuProps {
  onClose?: () => void;
}
export function SignoutMenu({ onClose }: SignoutMenuProps) {
  const navigate = useNavigate();
  const clerk = useClerk();
  return (
    <SignedIn>
      <MenuItem
        onClick={() => {
          clerk.signOut().then(() => {
            navigate(`/`);
            if (onClose) onClose();
          });
        }}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Sign out
      </MenuItem>
    </SignedIn>
  );
}

export function SigninMenu({ onClose }: SignoutMenuProps) {
  const clerk = useClerk();

  return (
    <SignedOut>
      <MenuItem onClick={() => clerk.openSignIn({})}>
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        Sign in
      </MenuItem>
    </SignedOut>
  );
}

export function SignoutButton() {
  const navigate = useNavigate();
  const { user, isSignedIn } = useUser();
  function onSignout() {
    console.log(`signed out:`, user?.fullName);
    navigate("/");
  }
  if (!isSignedIn) {
    return <> </>;
  }
  return (
    <SignOutButton signOutCallback={onSignout}>
      <Tooltip title="Sign out">
        <IconButton>
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </SignOutButton>
  );
}
