import React from "react";
import PolicyDialog from "./PolicyDialog";
import privacyPolicy from "./privacy.md";

interface PrivacyPolicyProps {
  open: boolean;
  onClose?: () => void;
}
export default function PrivacyPolicy({ open, onClose }: PrivacyPolicyProps) {
  console.log("PrivacyPolicy isOpen", open);
  console.log("PrivacyPolicy", privacyPolicy);
  return (
    <PolicyDialog
      open={open}
      title="Privacy policy"
      onClose={onClose}
      contentPath={privacyPolicy}
    />
  );
}
