import { Box, Stack } from "@mui/material";
import { errorNofification } from "../notification/notification";
import { useQuery } from "@apollo/client";
import { LIST_PRODUCTS } from "../../graphql/stripe";
import {
  ListProductsQuery,
  ListProductsQueryVariables,
} from "../../graphql/types";
import ProductCard from "./ProductCard";

export function ProductChooser() {
  const { data, error } = useQuery<
    ListProductsQuery,
    ListProductsQueryVariables
  >(LIST_PRODUCTS);
  if (error) errorNofification({ error });

  console.log("products", data);

  return (
    <Box>
      <Box sx={{ margin: "10px", minWidth: "500px" }}>
        <Stack direction={"row"} spacing={3}>
          {data &&
            data.products &&
            data.products.map((product: any, index: number) => {
              return (
                <ProductCard
                  productId={product.id as string}
                  key={(product?.id as string) + index.toString()}
                />
              );
            })}
        </Stack>
      </Box>
    </Box>
  );
}
