import Skeleton from '@mui/material/Skeleton';
import { TextFieldProps } from '@mui/material/TextField';
import { TextField as TF } from '@mui/material';


type LoadingTextFieldProps = TextFieldProps & {
  loading?: boolean;
};

export default function LoadingTextField(props: LoadingTextFieldProps) {
  const loading = props.loading;
  const tfProps = { ...props };
  delete tfProps.loading;

  return (
    <>
      {loading ? (<Skeleton
        variant="text"
        sx={{ width: 'calc(100% - 1em)' }}>
        <TF {...tfProps} />
      </Skeleton>
      ) : (

        <TF {...tfProps} />
      )}
    </>
  );
}


