import { Drawer, Grid } from "@mui/material";
import { NewProductCard } from "./NewProductCard";
import ProductCard from "./ProductCard";

export interface ProductDrawerProps {
  productId?: string;
  open?: boolean;
  onClose?(event: object, reason: string): void;
}

export function ProductDrawer({
  productId,
  open,
  onClose,
}: ProductDrawerProps) {
  function closeDrawer(event: object, reason: string) {
    if (onClose) onClose(event, reason);
  }
  return (
    <Drawer open={open} anchor="right" onClose={closeDrawer}>
      <Grid container sx={{ overflowY: "scroll" }}>
        {productId && <ProductCard productId={productId} />}
        {!productId && <NewProductCard />}
      </Grid>
    </Drawer>
  );
}
