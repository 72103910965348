import { CardContent, Skeleton, Typography } from "@mui/material";
import { User } from "../../../graphql/types";
import { InfoPane } from "../../common/InfoPane";

export interface UserCardContentProps {
  user: User;
  loading?: boolean;
}
export function UserCardContent({ user, loading }: UserCardContentProps) {
  const data = [
    {
      label: "ID",
      value: !loading ? user.id : undefined,
    },
    {
      label: "Username",
      value: !loading ? user.username : undefined,
    },
    {
      label: "Email",
      value: !loading
        ? user.emailAddresses &&
          user.emailAddresses[0] &&
          user.emailAddresses[0].emailAddress
        : undefined,
    },
    {
      label: "First name",
      value: !loading ? user.firstName : undefined,
    },
    {
      label: "Last name",
      value: !loading ? user.lastName : undefined,
    },
    {
      label: "Last signed in",
      value: !loading
        ? user.lastSignInAt
          ? new Date(user.lastSignInAt).toISOString()
          : ""
        : undefined,
    },
  ];
  return (
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Account
      </Typography>
      <InfoPane info={data} loading={loading} />
    </CardContent>
  );
}

/**
 * @deprecated since version 1.2. Use `UserCardContent` instead.
 */
export function UserCardContentOld({ user, loading }: UserCardContentProps) {
  return (
    <CardContent>
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>ID:</strong> {user.id}
        </Typography>
      )}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Username:</strong> {user.username || ""}
        </Typography>
      )}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Email:</strong>{" "}
          {user.emailAddresses &&
          user.emailAddresses[0] &&
          user.emailAddresses[0].emailAddress
            ? user.emailAddresses[0].emailAddress
            : ""}
        </Typography>
      )}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>First:</strong> {user ? user.firstName : ""}
        </Typography>
      )}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Last:</strong> {user ? user.lastName : ""}
        </Typography>
      )}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{ mt: 1 }}
        >{`Last signed: ${
          user && user.lastSignInAt
            ? new Date(user.lastSignInAt).toISOString()
            : ""
        }`}</Typography>
      )}
    </CardContent>
  );
}
