import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import { errorNofification } from "../notification/notification";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import { PRICES } from "../../graphql/stripe";
import { PricesQuery } from "../../graphql/types";

export function PriceList() {
  // const [subscriptionData, setSubscriptionData] = useState(null);
  const { data, loading, error } = useQuery<PricesQuery>(PRICES);

  if (error) errorNofification({ error });

  const columns: GridColDef[] = [
    {
      field: "plan",
      headerName: "Plan",
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.price.product.name}`,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.price.product.unit_amount / 100
        } ${params.row.price.currency.toUpperCase()}`,
    },
    {
      field: "subscriptionStatus",
      headerName: "Subscription",
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            onClick={() => {
              createSubscription(params.row.price.product.id);
            }}
          >
            Subscribe
          </Button>
        );
      },
    },
  ];

  async function createSubscription(priceId: string) {}
  return (
    <Box>
      <Card sx={{ margin: "10px", minWidth: "500px", maxWidth: "650px" }}>
        <CardHeader title="Select a plan" />
        <CardActions></CardActions>
        <CardContent>
          <DataGrid
            autoHeight
            sx={{ minHeight: "20em" }}
            density="compact"
            rows={data ? data.prices : []}
            loading={loading}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
    </Box>
  );
}
