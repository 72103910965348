import { Skeleton, Typography, TypographyOwnProps } from "@mui/material";

type LoadingTypographyProps = TypographyOwnProps &
  React.PropsWithChildren & {
    loading?: boolean;
  };

export default function LoadingTypography(props: LoadingTypographyProps) {
  const loading = props.loading;
  const tyProps = { ...props };
  delete tyProps.loading;
  return (
    <>
      {loading ? (
        <Skeleton variant="text">
          <Typography {...tyProps}>{tyProps.children}</Typography>
        </Skeleton>
      ) : (
        <Typography {...tyProps}>{tyProps.children}</Typography>
      )}
    </>
  );
}
