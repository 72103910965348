import { Drawer, Grid } from "@mui/material";
import { CustomerCard } from "../customer/CustomerCard";
import { useQuery } from "@apollo/client";
import { MeQuery, MeQueryVariables } from "../../../graphql/types";
import { ME } from "../../../graphql/user";
import { errorNofification } from "../../notification/notification";
import { useUser } from "@clerk/clerk-react";
import { stripeCustomerId } from "../../common/variables";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { Loading } from "../../common/Loading";

export interface UserDrawerProps {
  customerId?: string;
  open?: boolean;
  imageUrl?: string;
  onClose?(): void;
}
export function UserDrawer({
  customerId,
  open,
  imageUrl,
  onClose,
}: UserDrawerProps) {
  const { user } = useUser();
  const location = useLocation();
  const {
    data: meData,
    error: meError,
    loading: meLoading,
  } = useQuery<MeQuery, MeQueryVariables>(ME, {
    fetchPolicy: "cache-first",
    skip: !open,
  });

  // Close the drawer when the route changes
  useEffect(() => {
    if (onClose) onClose();
  }, [location]);

  if (meError) errorNofification({ error: meError });

  if (meData?.me?.customer) {
    stripeCustomerId(meData.me.customer.id as string);
  }

  function closeDrawer(event: object, reason: string) {
    if (onClose) onClose();
  }
  return (
    <Drawer open={open} anchor="right" onClose={closeDrawer}>
      <Grid container sx={{ overflowY: "scroll" }}>
        <Loading loading={meLoading} />
        <CustomerCard
          customerId={
            customerId || meData?.me?.customer?.id || "no-customer-id"
          }
          userImageUrl={user?.imageUrl}
        />
      </Grid>
    </Drawer>
  );
}
