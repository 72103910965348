import { gql } from "@apollo/client";
import { SubscriptionFragment } from "./stripe";
export const CustomerFragment = gql`
  fragment CustomerFragment on Customer {
    id
    name
    email
    created
    description
    balance
    phone
    taxExempt
    subscriptions {
      ...SubscriptionFragment
    }
  }
  ${SubscriptionFragment}
`;

export const UsageFragment = gql`
  fragment UsageFragment on Usage {
    id
    userId
    customer {
      ...CustomerFragment
    }
    usedCount
    allowedCount
    isPaid
  }
  ${CustomerFragment}
`;

// export const USAGE = gql`
//   query Usage($userId: ID!) {
//     usage(userId: $userId){
//       ...UsageFragment
//     }
//   }
//   ${UsageFragment}
// `;

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    username
    firstName
    lastName
    lastSignInAt
    createdAt
    updatedAt
    imageUrl
    hasImage
    primaryPhoneNumberId
    phoneNumbers {
      id
      phoneNumber
    }
    agreedToTermsAndConditions
  }
`;

export const USER_LIST = gql`
  query UserList {
    users {
      ...UserFragment
      customer {
        ...CustomerFragment
      }
    }
  }
  ${UserFragment}
  ${CustomerFragment}
`;

export const USER_DETAILS = gql`
  query UserDetails($id: ID!) {
    user(userId: $id) {
      ...UserFragment
      customer {
        ...CustomerFragment
      }
      emailAddresses {
        id
        emailAddress
        linkedTo {
          id
          type
        }
      }
      phoneNumbers {
        id
        phoneNumber
      }
      lastSignInAt
      createdAt
      updatedAt
      imageUrl
      hasImage
      # subscriptionUrl
    }
  }
  ${UserFragment}
  ${CustomerFragment}
`;

export const ME = gql`
  query Me {
    me {
      ...UserFragment
      customer {
        ...CustomerFragment
      }
      primaryClerkEmailAddressId
      emailAddresses {
        id
        emailAddress
      }
    }
  }
  ${UserFragment}
  ${CustomerFragment}
`;

export const CUSTOMER = gql`
  query Customer($customerId: ID!) {
    customer(customerId: $customerId) {
      ...CustomerFragment
    }
  }
  ${CustomerFragment}
`;

export const CUSTOMER_PORTAL_SESSION = gql`
  query CustomerPortalSession($customerId: ID!, $returnUrl: URL!) {
    customerPortalSession(customerId: $customerId, returnUrl: $returnUrl) {
      id
      url
      returnUrl
    }
  }
`;

export const AGREE_TO_TERMS = gql`
  mutation AgreeToTerms {
    agreeToTermsAndConditions
  }
`;

export const DISAGREE_TO_TERMS = gql`
  mutation DisgreeToTerms {
    disagreeToTermsAndConditions
  }
`;

export const AGGREMENT_TC_EVENT = gql`
  subscription AggrementTcEvent($userId: ID!) {
    agreementToTermsAndConditions(userId: $userId) {
      userId
      agreedToTermsAndConditions
      at
    }
  }
`;
