import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Skeleton,
  Typography,
  CardContent,
} from "@mui/material";

import { NEW_CHECKOUT_SESSION, PRODUCT } from "../../graphql/stripe";
import {
  NewCheckoutSessionMutation,
  NewCheckoutSessionMutationVariables,
  ProductQuery,
  ProductQueryVariables,
} from "../../graphql/types";
import { ME } from "../../graphql/user";
import { errorNofification } from "../notification/notification";
import { stripeCheckoutSessionId } from "../../graphql/globals";
import { Box } from "@mui/system";
import { useId } from "react";

export type ProductCardProps = {
  productId: string;
};
export default function ProductCard(props: ProductCardProps) {
  const id = useId();

  const {
    data: productData,
    error: productError,
    loading: productLoading,
  } = useQuery<ProductQuery, ProductQueryVariables>(PRODUCT, {
    variables: { productId: props.productId },
    fetchPolicy: "cache-first",
  });

  if (productError) errorNofification({ error: productError });

  const [newCheckOutSession, { error: checkOutSessionError }] = useMutation<
    NewCheckoutSessionMutation,
    NewCheckoutSessionMutationVariables
  >(NEW_CHECKOUT_SESSION);
  if (checkOutSessionError) errorNofification({ error: checkOutSessionError });
  function onCheckOut() {
    console.log("onCheckOut price", productData?.product?.prices);
    newCheckOutSession({
      variables: {
        priceIds: productData?.product?.prices
          ? (productData.product.prices.map((p: any) => p.id) as string[])
          : [],
        successUrl: `${window.location.origin}/#/admin/payment/completion`,
        cancelUrl: `${window.location.origin}/#/admin/payment/cancel`,
      },
      onCompleted(data) {
        console.log("sessiondata", data);
        stripeCheckoutSessionId(data.newCheckoutSession?.id as string);
        window.location.href = data.newCheckoutSession?.url as string;
      },
      onError(error) {
        errorNofification({ error });
      },
      refetchQueries: [{ query: ME }],
    });
  }

  return (
    <Box>
      <Card id={id} sx={{ width: 390 }}>
        <CardHeader title={productData?.product?.name || ""} />
        <CardContent sx={{ m: "1em", minWidth: 340 }}>
          {productLoading && (
            <Skeleton
              variant="rectangular"
              height={100}
              sx={{ objectFit: "contain" }}
            />
          )}
          {!productLoading && (
            <CardMedia
              sx={{ objectFit: "contain" }}
              component="img"
              height="194"
              image={
                productData?.product?.images && productData?.product.images[0]
                  ? productData?.product.images[0]
                  : undefined
              }
              alt={productData?.product?.name || ""}
            />
          )}
          <Typography sx={{ height: "8.5ex" }}>
            {productLoading ? (
              <Skeleton sx={{ height: "8.5ex" }} />
            ) : (
              productData?.product?.description
            )}
          </Typography>
          <p />
          {productLoading && (
            <Skeleton variant="text" sx={{ fontSize: "1rem", m: "1em" }} />
          )}
          {productData?.product?.prices?.length === 0 && (
            <Typography variant="body2">No prices available</Typography>
          )}
        </CardContent>

        <CardActions>
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={onCheckOut}
            size="small"
            color="primary"
          >
            Subscribe
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
