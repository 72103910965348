import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { SubscriptionChip, customerSubscriptionStatus } from "../../subscriptions/SubscriptionStatus";
import { Avatar } from "@mui/material";




export const userColumns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'avatar',
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      return <Avatar src={params.row.imageUrl}
        alt="avatar"
        sx={{ width: 24, height: 24 }} />;
    },
  },

  {
    field: 'firstName',
    headerName: 'First name',
    minWidth: 150,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    minWidth: 150,
  },
  {
    field: 'username',
    headerName: 'User name',
    minWidth: 150,
  },
  {
    field: 'subscriptionStatus',
    headerName: 'Subscription',
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      return <SubscriptionChip status={customerSubscriptionStatus(params.row.customer)} />;
    },

  }
];
