import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableCellProps,
  TableRow,
  TableRowProps,
} from "@mui/material";
import React from "react";

type LoadingTableCellProps = TableCellProps & {
  loading?: boolean;
};
export function LoadingTableCell(props: LoadingTableCellProps) {
  console.log("LoadingTableCell", props);
  if (props.loading) {
    return (
      <TableCell {...props}>
        <Skeleton />
      </TableCell>
    );
  } else {
    return <TableCell {...props}>{props.children || ""}</TableCell>;
  }
}

type infoTableRowProps = TableRowProps & {
  loading?: boolean;
  label?: string;
  value: any;
};

export function InfoRow(props: infoTableRowProps) {
  console.log("InfoRow", props);
  return (
    <TableRow>
      <TableCell>{props.label && <strong>{props.label}</strong>}</TableCell>
      <LoadingTableCell loading={props.loading}>{props.value}</LoadingTableCell>
    </TableRow>
  );
}

type InfoPaneProps = {
  info: {
    label?: string;
    value?: any;
  }[];
  loading?: boolean;
};
export function InfoPane(props: InfoPaneProps) {
  console.log("InfoPane", props);
  return (
    <Table
      size="small"
      aria-label="InfoPane-table"
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
        },
      }}
    >
      <TableBody>
        {props.info.map((info, index) => (
          <InfoRow
            key={index}
            label={info.label}
            value={info.value}
            loading={props.loading}
          />
        ))}
      </TableBody>
    </Table>
  );
}
