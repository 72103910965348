import { useQuery } from "@apollo/client";
import {
  Customer,
  CustomerQuery,
  CustomerQueryVariables,
} from "../../../graphql/types";
import { Avatar, Card, CardActions, CardHeader, Skeleton } from "@mui/material";
import { CUSTOMER } from "../../../graphql/user";
import { errorNofification } from "../../notification/notification";
import { BillingPortal } from "../../subscriptions/BillingPortal";
import {
  SubscriptionChip,
  SubscriptionStatus,
  customerSubscriptionStatus,
} from "../../subscriptions/SubscriptionStatus";
import BuySubscriptionButton from "../../subscriptions/BuySubscriptionButton";
import { CustomerCardContent } from "./CustomerCardContent";
type CustomerCardProps = {
  customerId?: string;
  userImageUrl?: string | null;
  loading?: boolean;
};

export function CustomerCard({
  customerId,
  userImageUrl,
  loading,
}: CustomerCardProps) {
  // const [showAllSubscriptions, setShowAllSubscriptions] = useState<boolean>(false);
  const {
    data: customerData,
    error: customerError,
    loading: customerLoading,
  } = useQuery<CustomerQuery, CustomerQueryVariables>(CUSTOMER, {
    variables: {
      customerId: customerId || "no-customer-id",
    },
    skip: !customerId,
    fetchPolicy: "cache-first",
  });

  function subscriptionAction(customer: Customer) {
    const subscriptionStatus: SubscriptionStatus =
      customerSubscriptionStatus(customer);
    switch (subscriptionStatus) {
      case SubscriptionStatus.Subscribed:
        return <SubscriptionChip status={subscriptionStatus} />;
      case SubscriptionStatus.Cancelled:
        return (
          <BillingPortal
            customerId={customer.id as string}
            actionText="Renew Plan"
          />
        );
      default:
        return <BuySubscriptionButton />;
    }
  }
  if (customerError) errorNofification({ error: customerError });

  return (
    <Card variant="outlined" sx={{ margin: "1em", minWidth: 235 }}>
      <CardHeader
        avatar={
          <Avatar
            alt={customerData?.customer?.name || ""}
            src={userImageUrl as string}
          />
        }
        action={<></>}
        title={
          customerLoading ? (
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          ) : (
            (customerData?.customer?.name as string)
          )
        }
      />

      <CardActions>
        <>
          <BillingPortal customerId={customerId as string} />
          {customerLoading ? (
            <Skeleton>
              <SubscriptionChip status={SubscriptionStatus.Subscribed} />
            </Skeleton>
          ) : (
            subscriptionAction(customerData?.customer as Customer)
          )}
        </>
      </CardActions>
      <CustomerCardContent
        customer={customerData?.customer as Customer}
        loading={customerLoading || loading}
      />
    </Card>
  );
}
