import { gql } from "@apollo/client";

export const PriceFragment = gql`
  fragment PriceFragment on Price {
    id
    active
    currency
    unitAmount
    lookupKey
    recurring {
      interval
      intervalCount
      trialPeriodDays
    }
    tiersMode
    tiers {
      flatAmount
      flatAmountDecimal
      unitAmount
      unitAmountDecimal
      upTo
    }
  }
`;

export const SubscriptionFragment = gql`
  fragment SubscriptionFragment on ProductSubscription {
    id
    status
    currentPeriodStart
    currentPeriodEnd
    startDate
    items {
      id
      created
      plan {
        id
        product {
          id
          name
          description
        }
      }
      price {
        ...PriceFragment
      }
    }
    cancelAt
    canceledAt
    cancellationDetails {
      comment
      reason
      feedback
    }
  }
  ${PriceFragment}
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($subscriptionId: ID!) {
    cancelSubscription(subscriptionId: $subscriptionId) {
      id
      status
      canceledAt
    }
  }
`;

export const PAUSE_SUBSCRIPTION = gql`
  mutation PauseSubscription($subscriptionId: ID!) {
    pauseSubscription(subscriptionId: $subscriptionId) {
      id
      status
    }
  }
`;
export const RESUME_SUBSCRIPTION = gql`
  mutation ResumeSubscription($subscriptionId: ID!) {
    resumeSubscription(subscriptionId: $subscriptionId) {
      id
      status
    }
  }
`;

export const NEW_CHECKOUT_SESSION = gql`
  mutation NewCheckoutSession(
    $priceIds: [ID!]!
    $successUrl: URL!
    $cancelUrl: URL!
  ) {
    newCheckoutSession(
      priceIds: $priceIds
      successUrl: $successUrl
      cancelUrl: $cancelUrl
    ) {
      id
      url
    }
  }
`;

export const CHECKOUT_SESSION = gql`
  query CheckoutSession($sessionId: ID!) {
    checkoutSession(sessionId: $sessionId) {
      id
      status
      subscription {
        ...SubscriptionFragment
      }
    }
  }
  ${SubscriptionFragment}
`;

export const PRODUCT_SUBSCRIPTION = gql`
  query ProductSubscription($subscriptionId: ID!) {
    productSubscription(subscriptionId: $subscriptionId) {
      ...SubscriptionFragment
    }
  }
  ${SubscriptionFragment}
`;

export const PRODUCT_SUBSCRIPTION_LIST = gql`
  query ProductSubscriptionList($customerId: ID!) {
    productSubscriptions(customerId: $customerId) {
      ...SubscriptionFragment
    }
  }
  ${SubscriptionFragment}
`;

export const PRODUCT = gql`
  query product($productId: ID!) {
    product(productId: $productId) {
      id
      name
      description
      images
      defaultPrice {
        ...PriceFragment
      }
      prices {
        ...PriceFragment
      }
    }
  }
  ${PriceFragment}
`;

export const LIST_PRODUCTS = gql`
  query ListProducts {
    products {
      id
      name
      description
      images
      defaultPrice {
        ...PriceFragment
      }
      prices {
        ...PriceFragment
      }
    }
  }
  ${PriceFragment}
`;

export const STRIPE_CONFIG = gql`
  query StripeConfig {
    stripeConfig {
      publishableKey
    }
  }
`;

export const PRICES = gql`
  query Prices($productId: ID!) {
    prices(productId: $productId) {
      ...PriceFragment
      product {
        id
        name
        description
      }
    }
  }
  ${PriceFragment}
`;

export const NEW_PRODUCT_OVERAGE = gql`
  mutation NewProductFixedFeeOverage(
    $productName: String!
    $productDescription: String!
    $fixedFee: Float!
    $upTo: Int!
    $overagePricePerUnit: Float!
    $interval: RecuringInterval!
  ) {
    newProductFixedFeeOverage(
      productName: $productName
      productDescription: $productDescription
      fixedFee: $fixedFee
      upTo: $upTo
      overagePricePerUnit: $overagePricePerUnit
      interval: $interval
    ) {
      id
      name
      description
    }
  }
`;

// export const NEW_PRODUCT_OVERAGE = gql`
//   mutation NewProductFixedFeeOverage(
//      newProductFixedFeeOverage(
//      $productName: String!,
//      $productDescription: String!,
//      $fixedFee: Float!,
//      $upTo: Int!,
//      $overagePricePerUnit: Float!,
//      $interval: RecuringInterval!

//    ){
//      newProductFixedFeeOverage(
//       productName: $productName,
//       productDescription: $$productDescription,
//       fixedFee: $$fixedFee,
//       upTo: $upTo,
//       overagePricePerUnit: $overagePricePerUnit,
//       interval: $interval
//       ){
//         id
//         name
//         description
//       }
//    }
// `;
