import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, ButtonProps } from "@mui/material";

/**
 * Props for the ClipboardButton component.
 */
export interface ClipboardButtonProps extends ButtonProps {
  /**
   * The text to be copied to the clipboard.
   */
  text: string | undefined;
  /**
   * Specifies whether the button is disabled.
   */
  disabled?: boolean;
}

/**
 * A button component that copies text to the clipboard when clicked.
 * @param props The props for the ClipboardButton component.
 * @returns The ClipboardButton component.
 */
export function ClipboardButton(props: ClipboardButtonProps) {
  /**
   * Handles the click event of the button.
   */
  function handleClick() {
    if (!props.text) {
      return;
    }
    navigator.clipboard.writeText(props.text as string);
  }

  return (
    <Button
      {...props}
      disabled={props.disabled}
      onClick={handleClick}
      // variant='outlined'
      endIcon={<ContentCopyIcon />}
    >
      Copy
    </Button>
  );
}
