import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import React from 'react';
import { ReferenceChip } from '../../chat/References';
import { Source } from '../../../graphql/types';
import { SourceRelevance } from '../../../graphql/types';

export function RateReference(props: { id: string; reference: Source; onReferenceChange: (reference: Source) => void; }) {

  const [source, setSource] = React.useState<Source>(props.reference);

  let ratingValue: number;

  switch (source.relevance) {
    case SourceRelevance.Relevant:
      ratingValue = 3;
      break;
    case SourceRelevance.Neutral:
      ratingValue = 2;
      break;
    case SourceRelevance.Irrevelant:
      ratingValue = 1;
      break;
    default:
      ratingValue = 0;
      break;
  }


  function onChange(event: React.SyntheticEvent, value: number | null) {
    // console.log('[RateReference] onChange', value);
    const localSource = { ...source };
    switch (value) {
      case 3:
        localSource.relevance = SourceRelevance.Relevant;
        break;
      case 2:
        localSource.relevance = SourceRelevance.Neutral;
        break;
      case 1:
        localSource.relevance = SourceRelevance.Irrevelant;
        break;
      default:
        localSource.relevance = SourceRelevance.Unknown;
        break;
    }
    if (props.onReferenceChange) {
      props.onReferenceChange(localSource);
    }
    setSource(localSource);
  }

  return (
    <Stack direction={'row'} spacing={1}>
      <ReferenceChip key={props.id} reference={source} />
      <Rating max={3} onChange={onChange} value={ratingValue} />
    </Stack>
  );
}
