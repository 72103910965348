import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  styled,
  Dialog,
} from "@mui/material";
import BuySubscriptionButton from "./BuySubscriptionButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SubscribeDialog({ open }: { open: boolean }) {
  function handleClose() {
    // do something
  }
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="signup-dialog"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="signup-dialogg-title">
          Subscribe
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
        <DialogContent dividers>
          <p>Your free trial is over</p>
          <p>Subscribe to continue with Electra</p>
        </DialogContent>
        <DialogActions>
          <BuySubscriptionButton />
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
