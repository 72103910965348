import { Divider, ListItemIcon, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import StarRateIcon from "@mui/icons-material/StarRate";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import BugReportIcon from "@mui/icons-material/BugReport";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import { useElectraOrganisation } from "../auth/OrgHook";
import { SignedIn } from "@clerk/clerk-react";
import React from "react";
import { ProductDrawer } from "../products/ProductDraw";

interface AdminMenuProps {
  onClose?: () => void;
}
export function AdminMenu({ onClose }: AdminMenuProps) {
  const navigate = useNavigate();
  const { data: orgData } = useElectraOrganisation();
  const [productDrawOpen, setProductDrawOpen] = React.useState(false);

  if (orgData?.role !== "org:admin") {
    return <></>;
  }

  return (
    <SignedIn>
      {orgData?.role === "org:admin" && (
        <>
          <Divider />
          <MenuItem
            sx={{ color: "red" }}
            onClick={() => {
              // navigate(`admin/products/new`);
              setProductDrawOpen(true);

              if (onClose) onClose();
            }}
          >
            <ListItemIcon>
              <InventoryOutlinedIcon sx={{ color: "red" }} />
            </ListItemIcon>
            *** New overage product
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`admin/ratings`);
              if (onClose) onClose();
            }}
          >
            <ListItemIcon>
              <StarRateIcon />
            </ListItemIcon>
            Ratings
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`admin/tests`);
              if (onClose) onClose();
            }}
          >
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            Tests
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`admin/synonyms`);
              if (onClose) onClose();
            }}
          >
            <ListItemIcon>
              <CompareArrowsIcon />
            </ListItemIcon>
            Synonyms
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`admin/users`);
              if (onClose) onClose();
            }}
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            Users
          </MenuItem>
          <ProductDrawer
            open={productDrawOpen}
            onClose={(event, reason) => {
              setProductDrawOpen(false);
            }}
          />
        </>
      )}
    </SignedIn>
  );
}
