import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardActionArea from "@mui/material/CardActionArea";
import Paper from "@mui/material/Paper";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useId, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { Md5 } from "ts-md5";
import { References } from "./References";
import {
  AskMutation,
  AskMutationVariables,
  ReferenceDocument,
} from "../../graphql/types";
import { Protect, useUser } from "@clerk/clerk-react";
import { ClipboardButton } from "../common/ClipboardButton";
import { FormControlLabel, Checkbox } from "@mui/material";
import { CHAT_ASK } from "../../graphql/chat";
import { formatReferences } from "./utils";
import { ElementaryAvatar } from "../common/ElementaryAvatar";
import { errorNofification } from "../notification/notification";

interface Message {
  id: number;
  text: string;
  sender: string;
}

/**
 * Renders a chat component for real estate inquiries.
 * @returns JSX.Element
 */
export function AskChat() {
  const { user } = useUser();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [synonyms, setSynonyms] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const [askId, setAskId] = useState<string>("");
  const [references, setReferences] = useState<ReferenceDocument[]>([]);
  const [ask, { loading, error }] = useMutation<
    AskMutation,
    AskMutationVariables
  >(CHAT_ASK, {
    onCompleted({ ask }) {
      setMessages([
        ...messages,
        { id: messages.length + 1, text: ask.text, sender: "AI" },
      ]);
      setAskId(ask.id);
      setReferences(
        ask.references?.filter((r: any) => r !== null) as ReferenceDocument[]
      );
    },
  });
  if (error) errorNofification({ error });

  useEffect(() => {
    if (messages.length > 0) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [messages.length]);

  function formatMessages(messages: Message[]): string {
    return messages
      .map((m) => {
        return `${m.sender}: ${m.text}`;
      })
      .join("\n\n");
  }

  const handleSend = () => {
    if (input.trim() !== "") {
      console.log(input);
      const newMessage: Message = {
        id: messages.length + 1,
        text: input,
        sender: user?.firstName as string,
      };
      setMessages([...messages, newMessage]);
      ask({
        variables: {
          askId: askId,
          questionId: Md5.hashAsciiStr(input),
          question: input,
          withSynonym: synonyms,
        },
        refetchQueries: ["ME"],
      });
      setInput("");
    }
  };

  const handleInputChange = (event: any) => {
    setInput(event.target.value);
  };

  return (
    <Box>
      <Card sx={{ minWidth: "500px" }}>
        <CardHeader title="Elementary chat" />
        <CardActionArea>
          <CardActions sx={{ ml: 1 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setMessages([]);
                setAskId("");
                setReferences([]);
              }}
            >
              New chat
            </Button>
            <Protect permission={"org:ui:debug"}>
              <FormControlLabel
                sx={{ ml: "1em" }}
                control={
                  <Checkbox
                    onChange={(event) => {
                      setSynonyms(event.target.checked);
                    }}
                    checked={synonyms}
                  />
                }
                label="with Synonym"
              />
            </Protect>
          </CardActions>
        </CardActionArea>
        <CardContent>
          <Box
            sx={{
              maxHeight: "500px",
              overflow: "auto",
              p: 2,
              bgcolor: "grey.200",
            }}
          >
            {messages.map((message) => (
              <Message
                key={message.id}
                message={message}
                imgUrl={user?.imageUrl as string}
              />
            ))}
            <div ref={scrollRef} />
          </Box>
          <References references={references} />
          <Box sx={{ backgroundColor: "background.default" }}>
            <Grid container spacing={5}>
              <Grid item xs={10}>
                <TextField
                  sx={{ mt: 2 }}
                  size="small"
                  fullWidth
                  multiline={true}
                  rows={5}
                  placeholder="Type a question"
                  variant="outlined"
                  value={input}
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={2}>
                <LoadingButton
                  sx={{ mt: 2 }}
                  color="primary"
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleSend}
                  loading={loading}
                />
                <ClipboardButton
                  text={`${formatMessages(
                    messages
                  )}\n\nReferences:\n${formatReferences(references)}`}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

interface MessageProps {
  message: Message;
  imgUrl?: string;
}
/**
 * Renders a message component.
 * @param {MessageProps} props - The props for the message component.
 * @returns {JSX.Element} The rendered message component.
 */

export function Message(props: MessageProps) {
  const id = useId();
  const isBot = props.message.sender === "AI";

  return (
    <Box
      id={id}
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: "center",
        }}
      >
        {isBot ? (
          <ElementaryAvatar />
        ) : (
          <Avatar sx={{ bgcolor: "secondary.main" }} src={props.imgUrl} />
        )}

        <Paper
          variant="outlined"
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            backgroundColor: isBot ? "primary.light" : "secondary.light",
            borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
          }}
        >
          <Typography variant="body1">{props.message.text}</Typography>
        </Paper>
        {/* <Textarea name="plain" value={props.message.text} variant="plain"
          color={isBot ? "primary" : "success"}
          size='sm'
          readOnly={true}
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            borderRadius: isBot ? "20px 20px 20px 5px" : "20px 20px 5px 20px",
            minWidth: '600px'

          }} /> */}
      </Box>
    </Box>
  );
}
