import Chip from "@mui/material/Chip";
import { useId } from "react";
import { Rating } from "../../../graphql/types";

interface RatingChipProps {
  rating: Rating;
  onClick?: (rating: Rating) => void;
}




export function RatingChip(props: RatingChipProps) {
  const id = useId();
  function onChipClick() {
    console.log('[RatingChip] onChipClick', props.rating);
    if (props.onClick) props.onClick(props.rating);
  }

  switch (props.rating) {
    case Rating.Good:
      return <Chip id={id} label='Good' color='success' onClick={onChipClick} />;
    case Rating.Neutral:
      return <Chip id={id} label='Neutral' color='warning' />;
    case Rating.Bad:
      return <Chip id={id} label='Bad' color='error' />;
    default:
      return <Chip id={id} label='Un-rated' color='info' />;
  }
}
