import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


import { clerkConfig } from './components/auth/clerkConfig';
import { ClerkLoaded, ClerkProvider, } from '@clerk/clerk-react';


// import { AuthInitialize } from './components/auth/AuthInitialize';



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABL_KEY || 'no-stripe-key');



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ClerkProvider {...clerkConfig}>
      <ClerkLoaded>
        <Elements stripe={stripePromise} >
          <App />
        </Elements>
      </ClerkLoaded>
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
