import { useLazyQuery } from "@apollo/client";
import { errorNofification } from "../notification/notification";
import { CUSTOMER_PORTAL_SESSION } from "../../graphql/user";
import {
  CustomerPortalSessionQuery,
  CustomerPortalSessionQueryVariables,
} from "../../graphql/types";
import LoadingButton from "@mui/lab/LoadingButton";
import { SignedIn } from "@clerk/clerk-react";
import PaymentIcon from "@mui/icons-material/Payment";
import { ListItemIcon, MenuItem } from "@mui/material";
import { stripeCustomerId } from "../common/variables";

export enum BillingPortalType {
  Button = "button",
  Menu = "menu",
}

export type BillingPortalProps = {
  customerId?: string;
  actionText?: string;
  variant?: BillingPortalType;
};

export function BillingPortal(props: BillingPortalProps) {
  const [fetchBillingSession, { error: billingError, loading }] = useLazyQuery<
    CustomerPortalSessionQuery,
    CustomerPortalSessionQueryVariables
  >(CUSTOMER_PORTAL_SESSION, {
    variables: {
      customerId: stripeCustomerId(),
      returnUrl: window.location.href,
    },
    fetchPolicy: "network-only",
  });

  if (billingError) {
    errorNofification({ error: billingError });
  }

  function onBillingClick() {
    fetchBillingSession({
      onCompleted: (data: CustomerPortalSessionQuery) => {
        window.location.href = data.customerPortalSession?.url as string;
      },
    });
  }

  if (props.variant === BillingPortalType.Menu) {
    return (
      <SignedIn>
        <MenuItem onClick={onBillingClick}>
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          Billing
        </MenuItem>
      </SignedIn>
    );
  }
  return (
    <SignedIn>
      <LoadingButton
        loading={loading}
        onClick={onBillingClick}
        variant={props.actionText ? "outlined" : undefined}
        color={props.actionText ? "success" : undefined}
        sx={{ ml: 1 }}
      >
        {props.actionText || "Billing"}
      </LoadingButton>
    </SignedIn>
  );
}
