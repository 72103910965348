import React, { useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CHECKOUT_SESSION } from "../../graphql/stripe";
import {
  CheckoutSessionQuery,
  CheckoutSessionQueryVariables,
} from "../../graphql/types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from "@mui/material";
import { SubscriptionDetails } from "./SubscriptionDetails";
import { errorNofification } from "../notification/notification";

export function PaymentCompletion() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const checkoutSessionId = params.get("session_id") as string;
  const { data, loading, error } = useQuery<
    CheckoutSessionQuery,
    CheckoutSessionQueryVariables
  >(CHECKOUT_SESSION, {
    variables: {
      sessionId: checkoutSessionId,
    },
  });
  if (error) errorNofification({ error: error });

  useEffect(() => {
    console.log("data", data);
  }, [data]);
  return (
    <Box>
      <Card>
        <CardHeader title="Payment complete - Thank you!" />
        <CardContent>
          <Typography variant="body1">You are now subscribed for:</Typography>
          {loading && <LinearProgress />}
          <SubscriptionDetails
            subscriptionId={data?.checkoutSession?.subscription?.id as string}
          />
        </CardContent>
        <Button
          onClick={() => {
            navigate("/");
          }}
        >
          Start using Elementaty
        </Button>
      </Card>
    </Box>
  );
}
