import { gql } from "@apollo/client";

export const QuestionAnswerRatingFragment = gql`
  fragment QuestionAnswerRating on QuestionAnswerRating {
    id
    questionId
    question
    answer
    askId
    createdAt
    references {
      id
      name
      type
      source
      region
      language
    }
  }
`;

export const CHAT_ASK = gql`
  mutation Ask(
    $askId: ID!
    $questionId: ID!
    $question: String!
    $withSynonym: Boolean
  ) {
    ask(
      askId: $askId
      questionId: $questionId
      question: $question
      withSynonym: $withSynonym
    ) {
      id
      questionId
      text
      references {
        name
        source
        type
        id
      }
    }
  }
`;

export const QUESTION_ASK = gql`
  mutation Answer(
    $askId: ID!
    $questionId: ID!
    $question: String!
    $withSynonym: Boolean
    $minimumSimalarity: Float
  ) {
    answer(
      askId: $askId
      questionId: $questionId
      question: $question
      withSynonym: $withSynonym
      minimumSimalarity: $minimumSimalarity
    ) {
      id
      text
      references {
        id
        name
        type
        source
        region
        language
      }
    }
  }
`;

export const ANSWER_STREAM = gql`
  subscription AnswerStream($askId: ID!) {
    answerStream(askId: $askId) {
      askId
      text
    }
  }
`;

export const RECENT_QUESTION_ANSWER = gql`
  query Recents($days: Int) {
    recentQuestions(days: $days) {
      ...QuestionAnswerRating
    }
  }
  ${QuestionAnswerRatingFragment}
`;

export const USER_ACTIVITY = gql`
  query UserActivity($userId: ID!, $days: Int) {
    userQuestions(userId: $userId, days: $days) {
      ...QuestionAnswerRating
    }
  }
  ${QuestionAnswerRatingFragment}
`;
