import "./App.css";
import { HashRouter as Router } from "react-router-dom";
import { AppRoutes } from "./components/main/Routes";
import { MainLayout } from "./components/main/MainLayout";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthProvider } from "./AuthProvider";
import { ExpandableError } from "./components/notification/notification";
console.log("App.tsx");

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      "stripe-billing-portal": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const theme = createTheme();

console.log("theme", theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            Components={{
              expandableError: ExpandableError,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <MainLayout>
                <AppRoutes />
              </MainLayout>
            </LocalizationProvider>
          </SnackbarProvider>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
