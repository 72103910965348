import { ReferenceDocument } from "../../graphql/types";

export function formatReferences(references: ReferenceDocument[]): string {
  return references
    .map((r) => {
      return `${r.id} - ${r.name}: ${r.source}`;
    })
    .join("\n\n");
}

export function checkAndProcessAiLinit(error: any) {
  if (error.message === "AI_LIMIT_EXCEEDED") {
    return true;
  } else {
    return false;
  }
}
