import PolicyDialog from "./PolicyDialog";
import terms from "./terms.md";
import { AGREE_TO_TERMS, DISAGREE_TO_TERMS } from "../../../graphql/user";
import { errorNofification } from "../../notification/notification";
import { useMutation } from "@apollo/client";
interface TermsPolicyProps {
  open: boolean;
  onClose?: () => void;
  showAgree?: boolean;
}
export default function TermsOfService({
  open,
  onClose,
  showAgree,
}: TermsPolicyProps) {
  const [agree, { error: agreeError }] = useMutation(AGREE_TO_TERMS, {
    refetchQueries: ["Me"],
  });
  const [disagree, { error: disagreeError }] = useMutation(DISAGREE_TO_TERMS, {
    refetchQueries: ["Me"],
  });

  if (agreeError) errorNofification({ error: agreeError });
  if (disagreeError) errorNofification({ error: disagreeError });

  return (
    <PolicyDialog
      open={open}
      title="Terms of Service"
      onClose={onClose}
      contentPath={terms}
      showAgree
      onAgree={async () => {
        await agree();
        console.log("User agreed to terms of service");
      }}
      onDisagree={async () => {
        await disagree();
        console.log("User disagreed to terms of service");
      }}
    />
  );
}
