import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { RatingDetailsQuery, RatingDetailsQueryVariables, Rating } from '../../../graphql/types';
import {
  Card, CardActions, CardContent, CardHeader,
  Drawer,
  Grid,
  Skeleton,
  Typography
} from '@mui/material';
import { RateAnswer } from './RateAnswer';
import { RATING_DETAILS } from '../../../graphql/ratings';
import { errorNofification } from '../../notification/notification';




export function RatingCard() {
  const params = useParams();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<RatingDetailsQuery, RatingDetailsQueryVariables>(RATING_DETAILS,
    { variables: { askId: params.askId || 'no-askId' } });
  if (data) console.log('[RatingDetails] data', data);
  if (error) errorNofification({ error: error });

  const rating = Rating.Unrated; //TODO (data && data.rating) ? data.rating.rating : Rating.Unrated;

  function onRated(rating: Rating) {
    console.log('[RatingDetails] onRated', rating);
  }
  return (
    <Drawer
      anchor='right'
      open={true}
      onClose={() => navigate(-1)}
    >
      <Grid container sx={{ overflowY: "scroll" }}>
        <Card sx={{ margin: '10px', minWidth: '500px', maxWidth: '650px' }}>
          <CardHeader title='Rating' />
          <CardActions>

            <RateAnswer onRated={onRated} rating={rating} />

          </CardActions>
          <CardContent>
            <Typography variant='body1'><strong>askId:</strong> {(loading) ? <Skeleton /> : params.askId}</Typography>
            <Typography variant='body1'><strong>questionId:</strong> {(loading) ? <Skeleton /> : data?.rating?.questionId}</Typography>
            <Typography variant='body1'><strong>question:</strong> {(loading) ? <Skeleton /> : JSON.stringify(data?.rating?.question)}</Typography>
            <Typography variant='body1'><strong>answer:</strong> {(loading) ? <Skeleton /> : JSON.stringify(data?.rating?.answer)}</Typography>
            <Typography variant='body1'><strong>when:</strong> {(loading) ? <Skeleton /> : JSON.stringify(data?.rating?.createdAt)}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Drawer>
  );
}
