// experimental component using stripe-pricing-table

import { useStripe } from "@stripe/react-stripe-js";

export function PricingTable() {
  const stripe = useStripe();
  if (!stripe) {
    // Stripe.js hasn't yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return (<></>);
  }

  return (
    <div>
      <stripe-pricing-table pricing-table-id={'todo'}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABL_KEY || 'no-stripe-key'}>
      </stripe-pricing-table>
    </div>
  );
}