import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import {
  TestDetailsQuery,
  TestDetailsQueryVariables,
  TestResult,
} from "../../../../graphql/types";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import { TestResultList } from "./TestResultList";
import { useMemo } from "react";
import { TEST_DETAILS } from "../../../../graphql/ratings";
import { errorNofification } from "../../../notification/notification";

export function TestSuiteDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const testSuiteId = useMemo(() => {
    return params.testSuiteId || "no-testId";
  }, [params.testSuiteId]);
  const { data, error } = useQuery<TestDetailsQuery, TestDetailsQueryVariables>(
    TEST_DETAILS,
    { variables: { id: testSuiteId } }
  );
  const testSuite = useMemo(() => {
    return data?.testSuit;
  }, [data]);
  if (error) errorNofification({ error });
  if (data) console.log("[RatingDetails] data.test", data);
  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={() => navigate(-1)}
      SlideProps={{ direction: "left" }}
    >
      <Grid container sx={{ overflowY: "scroll" }}>
        <Card sx={{ margin: "10px", maxWidth: "850px" }}>
          <CardHeader title="Test Suite" />
          <CardActions></CardActions>
          <CardContent>
            <Typography variant="body1">
              <strong>Name:</strong> {JSON.stringify(testSuite?.name || "")}
            </Typography>
            <Typography variant="body1">
              <strong>Time stamp:</strong>{" "}
              {JSON.stringify(testSuite?.timestamp)}
            </Typography>
            <TestResultList
              tests={(testSuite?.results as TestResult[]) || []}
              testSuiteId={testSuiteId}
            />
          </CardContent>
        </Card>
      </Grid>
    </Drawer>
  );
}
