import { GraphQLError } from "graphql/error";
import {
  enqueueSnackbar,
  CustomContentProps,
  SnackbarContent,
  useSnackbar,
} from "notistack";
import { StripeError } from "@stripe/stripe-js";
import React from "react";
import { useState, useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { useCopyToClipboard } from "usehooks-ts";

const useStyles = makeStyles(() => ({
  root: {
    "@media (min-width:600px)": {
      minWidth: "344px !important",
    },
  },
  card: {
    width: "100%",
    backgroundColor: "#f50057",
  },
  typography: {
    color: "white",
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between",
  },
  icons: {
    marginLeft: "auto",
  },
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    color: "#000",
    transition: "all .2s",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  paper: {
    backgroundColor: "#fff",
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: "none",
  },
}));

type AllError = Error | GraphQLError | StripeError | any;

declare module "notistack" {
  interface VariantOverrides {
    // adds `reportComplete` variant and specifies the
    // "extra" props it takes in options of `enqueueSnackbar`
    expandableError: {
      error: AllError;
    };
  }
}

// experimental, see https://notistack.com/features/customization
interface ExpandableErrorProps extends CustomContentProps {
  error: AllError;
}

export const ExpandableError = React.forwardRef<
  HTMLDivElement,
  ExpandableErrorProps
>((props, ref) => {
  const {
    id,
    message,
    //  custom props 👇🏼
    error,
    ...other
  } = props;

  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [copiedText, copy] = useCopyToClipboard();

  if (copiedText) console.log("Copied:", copiedText);

  function verboseError(error: any) {
    return {
      name: error.name,
      message: error.message,
      cause: error.cause,
      stack: error.stack,
      error: error,
    };
  }

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleCopy = useCallback(() => {
    copy(JSON.stringify(verboseError(error), null, 2))
      .then(() => {
        console.log("&&&&&!", copiedText);
      })
      .catch((error) => {
        // Handle copy error
        console.error("Failed to copy!", error);
      });
  }, []);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography variant="body2" className={classes.typography}>
            {props.message}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              size="small"
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton
              size="small"
              className={classes.expand}
              onClick={handleDismiss}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.paper}>
            <Button size="small" color="primary" className={classes.button}>
              <ContentCopy className={classes.checkIcon} onClick={handleCopy} />
            </Button>
            <Typography
              gutterBottom
              variant="caption"
              style={{ color: "#000", display: "block" }}
            >
              {error.cause && <pre>{JSON.stringify(error.cause, null, 2)}</pre>}
              {error.stack && <pre>{error.stack}</pre>}
            </Typography>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

export type ErrorNotificationProps = {
  error: AllError;
};
export function errorNofification(props: ErrorNotificationProps) {
  enqueueSnackbar(props.error.message, {
    variant: "expandableError",
    error: props.error,
    persist: true,
  });
}

export type NotificationProps = {
  message: string;
};
export function infoNotification(props: NotificationProps) {
  enqueueSnackbar(props.message, {
    variant: "info",
    autoHideDuration: 5000,
  });
}
export function warningNofification(props: NotificationProps) {
  enqueueSnackbar(props.message, {
    variant: "warning",
    autoHideDuration: 5000,
  });
}
export function successNofification(props: NotificationProps) {
  enqueueSnackbar(props.message, {
    variant: "success",
    autoHideDuration: 5000,
  });
}

export function aiLimitExcedded() {
  warningNofification({ message: "AI limit excedded" });
}
