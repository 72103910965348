import { useQuery } from "@apollo/client";
import { Box, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router";
import { SynonymsQuery, SynonymsQueryVariables } from "../../../graphql/types";
import { LoadingButton } from "@mui/lab";
import SynonymDetails from "./SynonymDetails";
import { LIST_SYNONYMS } from "../../../graphql/synonym";
import { errorNofification } from "../../notification/notification";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", minWidth: 100 },
  {
    field: "common",
    headerName: "Common",
    flex: 0.5,
  },

  {
    field: "legal",
    headerName: "Legal",
    flex: 1.5,
  },
  {
    field: "createdAt",
    headerName: "Created",
  },
  {
    field: "updatedAt",
    headerName: "Updated",
  },
];

export default function SynonymList() {
  const navigate = useNavigate();
  const params = useParams();
  const { data, loading, error } = useQuery<
    SynonymsQuery,
    SynonymsQueryVariables
  >(LIST_SYNONYMS);
  if (error) errorNofification({ error });

  function handleRowClick(params: GridRowParams) {
    const synonym = params.row;
    console.log("clicked synonym:", synonym);
    navigate(`${synonym.id}`);
  }
  return (
    <Box sx={{ minWidth: "800px" }}>
      <Card sx={{ margin: "10px" }}>
        <CardHeader title="Synonyms" />
        <CardActions sx={{ ml: 1 }}>
          <LoadingButton
            loading={loading}
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("new");
            }}
          >
            New
          </LoadingButton>
        </CardActions>
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              autoHeight
              sx={{ minHeight: "20em" }}
              density="compact"
              rows={data?.synonyms || []}
              loading={loading}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    createdAt: false,
                  },
                },
              }}
              pageSizeOptions={[25]}
              disableRowSelectionOnClick
              onRowClick={handleRowClick}
              slots={{ toolbar: GridToolbar }}
            />
          </Box>
        </CardContent>
        {params.synonymId && <SynonymDetails />}
      </Card>
    </Box>
  );
}
