import { Stack } from "@mui/system";
import { useQuery } from "@apollo/client";
import { PRODUCT_SUBSCRIPTION } from "../../graphql/stripe";
import {
  ProductSubscriptionQuery,
  ProductSubscriptionQueryVariables,
} from "../../graphql/types";
import { errorNofification } from "../notification/notification";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import LoadingDateField from "../common/LoadingDateField";
import { red } from "@mui/material/colors";

export function SubscriptionDetails(props: { subscriptionId: string }) {
  const { data, loading, error } = useQuery<
    ProductSubscriptionQuery,
    ProductSubscriptionQueryVariables
  >(PRODUCT_SUBSCRIPTION, {
    variables: {
      subscriptionId: props.subscriptionId,
    },
  });
  if (!props.subscriptionId) return null;

  if (error) errorNofification({ error: error });

  if (data) console.log("SubscriptionDetails", data.productSubscription);

  if (error) errorNofification({ error: error });

  const item = data?.productSubscription?.items
    ? data.productSubscription.items[0]
    : undefined;

  return (
    <Card sx={{ borderColor: red }}>
      <CardHeader
        title={item?.plan?.product?.name || ""}
        subheader={`Subscription Id: ${data?.productSubscription?.id || ""}`}
      />
      <CardContent>
        <Divider />
        <Typography>
          Status: {data?.productSubscription?.status || ""}
        </Typography>
        <LoadingDateField
          loading={loading}
          variant="standard"
          id="startDate"
          label="Subscription started"
          defaultValue={dayjs(data?.productSubscription?.startDate) || null}
        />
        <Stack direction="row" spacing={2}>
          <LoadingDateField
            loading={loading}
            variant="standard"
            id="currentPeriodStart"
            label="Billing period start"
            defaultValue={
              dayjs(data?.productSubscription?.currentPeriodStart) || null
            }
          />
          <LoadingDateField
            loading={loading}
            variant="standard"
            id="currentPeriodEnd"
            label="Billing period end"
            defaultValue={
              dayjs(data?.productSubscription?.currentPeriodEnd) || null
            }
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
