import { SignedIn, SignedOut } from "@clerk/clerk-react";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { IconButton, ListItemIcon, ListItemText, Divider } from "@mui/material";
import React from "react";
import { AdminMenu } from "../admin/AdminMenu";
import PrivacyPolicy from "../admin/legal/Privacy";
import TermsOfService from "../admin/legal/TermsOfService";
import { SigninMenu, SignoutMenu } from "../admin/user/Signout";
import { UserDrawer } from "../admin/user/UserDraw";
import {
  BillingPortal,
  BillingPortalType,
} from "../subscriptions/BillingPortal";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import About from "./About";

export function HamburgerMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userOpen, setUserOpen] = React.useState(false);
  const [privacyOpen, setPrivacyOpen] = React.useState(false);
  const [termsOpen, setTermsOpen] = React.useState(false);
  const [aboutOpen, setAboutOpen] = React.useState(false);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function showProfile() {
    console.log("showProfile");
    setUserOpen(true);
    setAnchorEl(null);
  }

  function showPrivacyPolicy() {
    console.log("showPrivacyPolicy");
    setPrivacyOpen(true);
    setAnchorEl(null);
  }
  function showTerms() {
    console.log("showTerms");
    setTermsOpen(true);
    setAnchorEl(null);
  }

  function showAbout() {
    console.log("showAbout");
    setAboutOpen(true);
    setAnchorEl(null);
  }

  function goHome() {
    console.log("goHome");
    setAnchorEl(null);
    window.location.href = "/";
  }

  return (
    <>
      <Tooltip title="Menu">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleMenu}
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={showAbout}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>About</ListItemText>
        </MenuItem> */}
        <MenuItem onClick={goHome}>
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Home</ListItemText>
        </MenuItem>
        <SigninMenu onClose={handleClose} />
        <SignoutMenu onClose={handleClose} />
        <SignedIn>
          <MenuItem onClick={showProfile}>
            <ListItemIcon>
              <Person2OutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>

          <BillingPortal variant={BillingPortalType.Menu} />
        </SignedIn>
        <AdminMenu onClose={handleClose} />
        <Divider />
        <MenuItem onClick={showTerms}>
          <ListItemIcon>
            <PolicyOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Terms of service</ListItemText>
        </MenuItem>
        <MenuItem onClick={showPrivacyPolicy}>
          <ListItemIcon>
            <PolicyOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Privacy policy</ListItemText>
        </MenuItem>
      </Menu>

      <UserDrawer
        open={userOpen}
        onClose={() => {
          setUserOpen(false);
        }}
      />
      <PrivacyPolicy open={privacyOpen} onClose={() => setPrivacyOpen(false)} />
      <SignedIn>
        <TermsOfService
          open={termsOpen}
          onClose={() => setTermsOpen(false)}
          showAgree
        />
      </SignedIn>
      <SignedOut>
        <TermsOfService open={termsOpen} onClose={() => setTermsOpen(false)} />
      </SignedOut>
      {/* <About open={aboutOpen} onClose={() => setAboutOpen(false)} /> */}
    </>
  );
}
