
import { Skeleton } from '@mui/material';
import { DateField, DateFieldProps } from '@mui/x-date-pickers/DateField';
type LoadingDateFieldProps = DateFieldProps<any> & {
  loading?: boolean;
};

export default function LoadingDateField(props: LoadingDateFieldProps) {
  const loading = (props.loading) ? props.loading as boolean : false;
  const tfProps = { ...props };
  if (!tfProps.format) tfProps.format = 'DD MMMM YYYY';
  delete tfProps.loading;
  return (
    <>
      {loading ? (<Skeleton
        variant="text">
        <DateField {...tfProps} />
      </Skeleton>
      ) : (
        <DateField {...tfProps} />
      )}
    </>

  );
}
