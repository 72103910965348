import { useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";

export type ElectraOrganisation = {
  organization: {
    name: string;
    id: string;
    hasImage: boolean;
    imageUrl?: string;
    slug: string | undefined | null;
    createdAt: Date;
    updatedAt: Date;
  },
  role: string;
  permissions: string[];
};

export function useElectraOrganisation() {
  const { user, isLoaded: userIsLoaded, isSignedIn: userIsSignedIn } = useUser();
  const [status, setStatus] = useState<{
    loading: boolean;
    error?: Error;
    data?: ElectraOrganisation;
  }>({
    loading: false
  });

  function fetchOrganizations() {
    if (userIsLoaded && userIsSignedIn) {
      setStatus({ loading: true });
      user.getOrganizationMemberships()
        .then((memberships) => {
          console.log('[useElectraOrganisation] memberships', memberships);
          console.log('[useElectraOrganisation] memberships[0]', memberships[0]);
          if (memberships.length > 0) {
            setStatus({ loading: false, data: memberships[0] });
            return;
          } else {
            setStatus({ loading: false });
            return;
          }

        })
        .catch((error) => {
          setStatus({ loading: false, error });
          return;
        });
    }
  }

  useEffect(() => {
    fetchOrganizations();
  }, [userIsLoaded, userIsSignedIn]);

  return { ...status };

}

