import { useQuery } from '@apollo/client';
import { QuestionAnswerRating, RecentsQuery, RecentsQueryVariables, UserActivityQuery, UserActivityQueryVariables } from '../../graphql/types';
import { RECENT_QUESTION_ANSWER, USER_ACTIVITY } from '../../graphql/chat';
import { GridColDef } from '@mui/x-data-grid/models/colDef';
import {
  Card, CardHeader, CardContent,
  List, ListItem, ListItemText,
  Typography, Stack, ListItemButton
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { errorNofification } from '../notification/notification';
import dayjs from 'dayjs';

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width: 90 },

  {
    field: 'question',
    headerName: 'Question',
    minWidth: 400,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    width: 150,
    sortable: false,
    renderCell: ({ row }) =>
      <Typography variant='body2'>{dayjs(row.createdAt as Date).format('DD MMMM YYYY')}</Typography>
  }

];


export type ActivityDataGridProps = {
  userId: string;
};
export function ActivityDataGrid(props: ActivityDataGridProps) {
  const { data: activity, loading: activityLoading, error: activityError }
    = useQuery<UserActivityQuery, UserActivityQueryVariables>(USER_ACTIVITY, {
      variables: {
        userId: props.userId as string,
        days: 365
      },
    });
  if (activityError) errorNofification({ error: activityError });
  function handleRowClick(params: any) {
    console.log(params.row);
  }
  console.log('activity', activity);

  return (
    <Card sx={{ margin: '10px', minWidth: '500px' }}>
      <CardHeader title='Recent questions' />
      <CardContent>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            loading={activityLoading}
            autoHeight
            sx={{ minHeight: '20em' }}
            density='compact'
            getRowId={(row) => row.askId}
            rows={activity?.userQuestions || [] as QuestionAnswerRating[]}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
            onRowClick={handleRowClick}
          />
        </Box>
      </CardContent>
    </Card >
  );
}


export type QuestionRecentsListProps = {
  days?: number;
  reAsk?: (questionAnswer: QuestionAnswerRating) => void;
};
export function QuestionRecentsList(props: QuestionRecentsListProps) {
  const {
    data: recents,
    loading: recentsLoading,
    error: recentsError
  } = useQuery<RecentsQuery, RecentsQueryVariables>(RECENT_QUESTION_ANSWER, {
    variables: {
      days: props.days || 14
    },
    fetchPolicy: 'cache-and-network'
  });
  if (recentsError) errorNofification({ error: recentsError });

  // console.log('recents', recents);
  function itemClicked(questionAnswer: QuestionAnswerRating) {
    if (props.reAsk) {
      props.reAsk(questionAnswer);
    }
  }

  return (
    <Stack>
      <Typography variant='h6' sx={{ ml: 1 }}>Recent questions</Typography>
      <Typography variant='caption' sx={{ ml: 1 }}>Last 14 days</Typography>
      <List
        sx={{
          width: 350,
          height: 800,
          position: 'relative',
          overflow: 'auto',
          '& ul': { padding: 1 },
        }}

      >
        {recentsLoading && <ListItem><ListItemText primary='Loading ...' /></ListItem>}
        {recents && recents.recentQuestions.length > 0 && recents?.recentQuestions.map((questionAnswer: any, index: number) => (
          <RecentQuestionListItem key={index.toString()} questionAnswer={questionAnswer} onClick={itemClicked} />
        ))}
      </List>
    </Stack>
  );
}


type RecentQuestionListItemProps = {
  key: string;
  questionAnswer: QuestionAnswerRating;
  onClick?: (questionAnswer: QuestionAnswerRating) => void;
};

function RecentQuestionListItem(props: RecentQuestionListItemProps) {

  function itemClick(event: any) {
    if (props.onClick) {
      props.onClick(props.questionAnswer);
    }
  }
  return (
    <ListItem key={props.questionAnswer.askId} dense>
      <ListItemButton
        onClick={itemClick}>
        <ListItemText
          primary={props.questionAnswer.question}
          secondary={(props.questionAnswer.createdAt) ? dayjs(props.questionAnswer.createdAt as Date).format('DD MMMM YYYY') : ''}
        />
      </ListItemButton>
    </ListItem>
  );
}
