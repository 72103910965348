
import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import { Stack, Grid, Box } from '@mui/material';
import { Button } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';




export function PublicMain() {
  return (

    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      minHeight='100vh'
      minWidth='100vw'
    >
      <Grid item xs={3}>
        <h2>Get Started with Electra</h2>
        <Stack direction='row' spacing={2}>
          <SignUpButton mode='modal'
            afterSignInUrl='/'
          >
            <Button variant="contained" endIcon={<LoginIcon />} color='secondary'>
              Sign Up
            </Button>

          </SignUpButton>
          <SignInButton mode='modal'
            afterSignInUrl='/'
          >
            <Button variant="outlined" endIcon={<LoginIcon />} color='secondary'>
              Sign In
            </Button>

          </SignInButton>
        </Stack>
      </Grid>

    </Box>

  );
}
