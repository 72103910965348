import { Typography } from "@mui/material";
import MuiMarkdown, { getOverrides } from "mui-markdown";

interface CustomTypographyProps {
  variant: "h1" | "h2" | "h3" | "body1";
  children: React.ReactNode;
}

// Custom Typography component
const CustomTypography: React.FC<CustomTypographyProps> = ({
  variant,
  children,
}) => {
  return <Typography variant={variant}>{children}</Typography>;
};

export function AnswerBox({ children }: { children?: string | null }) {
  return (
    <MuiMarkdown
      overrides={{
        ...getOverrides({}), // This will keep the other default overrides.
        h1: { component: CustomTypography, props: { variant: "h3" } },
        h2: { component: CustomTypography, props: { variant: "h4" } },
        h3: { component: CustomTypography, props: { variant: "h5" } },
        h4: { component: CustomTypography, props: { variant: "h6" } },
        h5: { component: CustomTypography, props: { variant: "h7" } },
        p: { component: CustomTypography, props: { variant: "body1" } },
      }}
    >
      {children}
    </MuiMarkdown>
  );
}

export function PolicyBox({ children }: { children?: string | null }) {
  return (
    <MuiMarkdown
      overrides={{
        ...getOverrides({}), // This will keep the other default overrides.
        h1: { component: CustomTypography, props: { variant: "h5" } },
        h2: { component: CustomTypography, props: { variant: "h6" } },
        h3: { component: CustomTypography, props: { variant: "h7" } },
        h4: { component: CustomTypography, props: { variant: "h8" } },
        h5: { component: CustomTypography, props: { variant: "h9" } },
        p: { component: CustomTypography, props: { variant: "body1" } },
      }}
    >
      {children}
    </MuiMarkdown>
  );
}
