import React from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import UserIdentity from "../admin/user/UserIdentity";
import { ElementaryAvatar } from "../common/ElementaryAvatar";
import { SignedIn } from "@clerk/clerk-react";

import { HamburgerMenu } from "./HamburgerMenu";
import { SignoutButton } from "../admin/user/Signout";
import { isDevelopment } from "../../constants";

export function MainAppBar() {
  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar>
        <HamburgerMenu />
        <ElementaryAvatar />
        <Stack sx={{ ml: "1em" }}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Elementary
          </Typography>
          <Typography variant="caption" component="div">
            by Electra Ventures
          </Typography>
        </Stack>
        {isDevelopment && (
          <Typography
            variant="h3"
            color={"yellow"}
            sx={{
              ml: "1em",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            DEVELOPMENT
          </Typography>
        )}
        <Box sx={{ marginLeft: "auto" }}>
          <Stack direction="row" spacing={2}>
            <SignedIn>
              <UserIdentity />
              <SignoutButton />
            </SignedIn>
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export function MainLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MainAppBar />
        <Stack>
          <Toolbar />
          {children}
        </Stack>
        {/* <MainBottom /> */}
      </Box>
    </>
  );
}
