import { Button } from '@mui/material';
import { useNavigate } from 'react-router';


export default function BuySubscriptionButton() {
  const navigate = useNavigate();
  function upgrade() {
    navigate('/admin/products');
  }
  return (
    <Button onClick={upgrade} variant="contained" color="secondary">
      Subscribe
    </Button>
  );
}


