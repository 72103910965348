import { gql } from "@apollo/client";
export const LIST_SYNONYMS = gql`
  query Synonyms {
    synonyms {
      id
      common
      legal
      updatedAt
      createdAt
    }
  }
`;

export const SYNONYM = gql`
  query Synonym($synonymId: ID!) {
    synonym(synonymId: $synonymId) {
      id
      common
      legal
      createdAt
      updatedAt
    }
  }
`;

export const ADD_SYNONYM = gql`
  mutation AddSynonym($newSynonym: NewSynonym!) {
    addSynonym(newSynonym: $newSynonym) {
      id
      common
      legal
    }
  }
`;

export const UPDATE_SYNONYM = gql`
  mutation UpdateSynonym($synonymId: ID!, $updatedSynonym: UpdateSynonym!) {
    updateSynonym(synonymId: $synonymId, synonym: $updatedSynonym) {
      id
      common
      legal
    }
  }
`;

export const DELETE_SYNONYM = gql`
  mutation RemoveSynonym($synonymId: ID!) {
    removeSynonym(synonymId: $synonymId) {
      id
      common
      legal
    }
  }
`;

