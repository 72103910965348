import { CardContent, Skeleton, Typography } from "@mui/material";
import { Attribute, Customer } from "../../../graphql/types";

import { AttributeList } from "./AttributeList";
import { AddressCard } from "../../products/AddressCard";
import { InfoPane } from "../../common/InfoPane";

export interface CustomerCardProps {
  customer: Customer;
  loading?: boolean;
}
export function CustomerCardContent({ customer, loading }: CustomerCardProps) {
  const data = [
    {
      label: "ID",
      value: !loading ? customer.id : undefined,
    },
    {
      label: "Email",
      value: !loading ? customer.email : undefined,
    },
    {
      label: "Balance",
      value: !loading ? customer.balance?.toFixed(2) : undefined,
    },
    {
      label: "Created",
      value: !loading
        ? customer.created
          ? new Date(customer.created).toLocaleDateString()
          : ""
        : undefined,
    },
    {
      label: "Description",
      value: !loading ? customer.description : undefined,
    },
    {
      label: "Phone",
      value: !loading ? customer.phone : undefined,
    },
  ];
  return (
    <CardContent>
      <InfoPane info={data} loading={loading} />
      {customer?.address ? (
        loading ? (
          <Skeleton />
        ) : (
          <AddressCard address={customer.address} />
        )
      ) : (
        <></>
      )}
    </CardContent>
  );
}

export function CustomerCardContentOld({
  customer,
  loading,
}: CustomerCardProps) {
  return (
    <CardContent>
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>ID:</strong> {customer.id}
        </Typography>
      )}

      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Email:</strong> {customer.email}
        </Typography>
      )}

      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Balance:</strong> {customer.balance?.toFixed(2)}
        </Typography>
      )}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Created:</strong>{" "}
          {customer.created
            ? new Date(customer.created).toLocaleDateString()
            : ""}
        </Typography>
      )}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Description:</strong> {customer.description || ""}
        </Typography>
      )}
      {/* <Typography variant="body2" color="text.secondary">
          Delinquent: {customer.delinquent ? 'Yes' : 'No'}
        </Typography> */}
      {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Phone:</strong> {customer.phone || ""}
        </Typography>
      )}
      {/* {loading ? (
        <Skeleton />
      ) : (
        <Typography variant="body2" color="text.secondary">
          <strong>Tax exempt:</strong> {customer.taxExempt}
        </Typography>
      )} */}
      {/* <Typography variant="body2" color="text.secondary">
          Test Clock: {customer.testClock}
        </Typography> */}

      {loading ? <Skeleton /> : <AddressCard address={customer.address} />}
      {!loading && customer.metadata && customer.metadata.length > 0 && (
        <AttributeList attributes={customer.metadata as Attribute[]} />
      )}

      {/* Additional components can be added here for subscriptions and other fields */}
    </CardContent>
  );
}
