import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import {
  QuestionAnswerRating,
  RatingListQuery,
  RatingListQueryVariables,
} from "../../../graphql/types";
import { useNavigate, useParams } from "react-router";
import { RatingChip } from "./RatingChip";
import { RatingCard } from "./RatingCard";
import { RATING_LIST } from "../../../graphql/ratings";
import { errorNofification } from "../../notification/notification";
import { useElectraOrganisation } from "../../auth/OrgHook";

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width: 90 },

  {
    field: "question",
    headerName: "Question",
    minWidth: 400,
    // resizable: true,
    valueGetter: (params: GridValueGetterParams) => `${params.row.question}`,
  },

  {
    field: "rating",
    headerName: "Rating",
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      return <RatingChip rating={params.row.rating} />;
    },
  },
  {
    field: "createdAt",
    headerName: "When",
    minWidth: 200,
  },
];

export function RatingList() {
  const navigate = useNavigate();
  const params = useParams();
  const { data: orgData } = useElectraOrganisation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [ratingTypeFilter, setRatingTypeFilter] = React.useState(-1);

  const { data, loading, error } = useQuery<
    RatingListQuery,
    RatingListQueryVariables
  >(RATING_LIST);

  if (orgData?.role !== "org:admin") {
    return <></>;
  }

  if (error) errorNofification({ error: error });
  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }
  function handleRowClick(params: GridRowParams) {
    const rating = params.row as QuestionAnswerRating;
    console.log("clicked rating:", rating);
    navigate(`${rating.askId}`);
  }

  function handleRatingTypeChange(event: any) {
    setRatingTypeFilter(Number(event.target.value) as number);
  }

  if (orgData?.role !== "org:admin") {
    return <></>;
  }

  console.log("users:", data);

  return (
    <Card sx={{ margin: "10px", minWidth: "500px" }}>
      <CardHeader title="Ratings" />
      <CardActions>
        <FormControl>
          <InputLabel id="rating-type-select-filter-label">
            Rating type
          </InputLabel>
          <Select
            sx={{ width: 150 }}
            labelId="rating-type-select-filter-label"
            id="rating-type-select-filter"
            value={ratingTypeFilter}
            label="Rating type"
            onChange={handleRatingTypeChange}
          >
            <MenuItem value={-1}>Un-rated</MenuItem>
            <MenuItem value={0}>Incorrect</MenuItem>
            <MenuItem value={2}>Partly correct</MenuItem>
            <MenuItem value={3}>Correct</MenuItem>
          </Select>
        </FormControl>
      </CardActions>
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <DataGrid
            autoHeight
            sx={{ minHeight: "20em" }}
            density="compact"
            rows={data ? data.ratings : []}
            loading={loading}
            columns={columns}
            getRowId={(row) => row.askId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            // checkboxSelection
            disableRowSelectionOnClick
            onRowClick={handleRowClick}
            slots={{ toolbar: GridToolbar }}
          />
        </Box>
      </CardContent>
      {params.askId && <RatingCard />}
    </Card>
  );
}
